import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, EmailValidator } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { SigninService } from 'src/app/services/signin.service';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-setup-user',
  templateUrl: './setup-user.component.html',
  styleUrls: ['./setup-user.component.css']
})
export class SetupUserComponent implements OnInit {
  @ViewChild('userForm') public any: NgForm;
  edit: boolean; list: boolean; sel: any; act: any; id: number;
  usr: any; users: any[]; brn: any[]; res: any; exp: any; up: any;
  private _searhcTitle: string; filtered: any[] = []; response:any;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.serachResult(value);
  }
  constructor(private _setup: SetupService, private _api: SigninService, private _ext: ExportService, private _use: NavComponent) { }

  ngOnInit() {
    this.act = this._use.active;
    this.formReset();
    this.getBranches();
    this.getAllUsers();
  }

  getBranches() {
    this._setup.getBranchs().subscribe((data: any) => {
      this.brn = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Branch Details'); });
  }

  getAllUsers() {
    this._setup.getUsers().subscribe((data: any) => {
      this.users = data.data; this.filtered = data.data; this.response = data
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'User Lists', 'Unable to Get All Users'); });
  }

  editUser(data) {
    this.usr = data;
    this.edit = true; this.list = true;
  }

  delUser(dat) {
    this._setup.delUser(dat.id).subscribe((data: any) => {
      this.getAllUsers();
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Deleting Fail', 'Unable to Delete User'); });
  }

  reset(dat) {
    this._api.Reset(dat.email).subscribe((data: any) => {
      this.res = data;
      this._use.showMsg('success', 'User Account Reset', 'User Shld Check Email for his new password');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Reset Fail', 'Unable to Reset User');
    });
  }

  postUser() {
    if (this.usr.password === this.usr.conpassword) {
      this._setup.postUser(this.usr).subscribe((data: any) => {
        this.res = data; this.getAllUsers();
        this.formReset(this.any); this.list = false;
        this._use.showMsg('success', 'User Added', 'Added to list Successfully');
      }, (err: HttpErrorResponse) => {
        this._use.showMsg('error', 'Adding Fail', 'Unable to Add New User');
      });
    } else {
      this._use.showMsg('error', 'Adding Fail', 'User password do not match');
    }
  }

  putUser() {
    this.usr.userid = this._use.active.id; this.usr.date = new Date(); this.usr.branch=null;
    this.usr.muserid = this._use.active.id; this.usr.mdate = new Date();
    this._setup.putUser(this.usr.id, this.usr).subscribe((data: any) => {
      this.res = data; this.getAllUsers();
      this.formReset(this.any); this.list = false;
      this._use.showMsg('success', 'User Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  uploadUsers() {
    this.up.users = this._ext.import;
    this._setup.uploadUser(this.up).subscribe((data: any) => {
      this.res = data; this.getAllUsers();
      this.formReset(this.any); this.list = false;
      this._use.showMsg('success', 'User Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.usr = { id: '', fullname: '', email: '', mobile: '',
        username: '', password: '', conpassword: '',
        usertype: '', privillege: 'Branch', branchid: this._use.active.branchid,
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null };
      this.up = { users: [], branchid: this._use.active.branchid,
        userid: this._use.active.id, date: new Date() };
    }
    this.edit = false;
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.users.filter(a => a.fullname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.username.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.usertype.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.privillege.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1 || a.branch.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.branch.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 );
  }
  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this._ext.exportAsExcelFile(data, 'users');
      this._use.showMsg('success', 'Users Exported', 'Check Downloaded Excel File For Details');
    } else {
      this._use.showMsg('error', 'Users Export Fail', 'There is no Users Data to Export');
    }
  }
  read(event) {
    this._ext.readExcel(event.target.files[0]);
    // console.log(this.imp);
    // document.getElementById('upload').click();
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, USERID: e.id, FULLNAME: e.fullname, MOBILE: e.mobile,
        EMAIL: e.email, USERNAME: e.username, PASSWORD: e.password,
        USERTYPE: e.usertype, PRIVILLEGE: e.privillege, BRANCH: e.branch.name
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  listView() {
    this.list = true; this.formReset();
  }
  back() {
    this.list = false; this.formReset();
  }
}

import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SigninService } from 'src/app/services/signin.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  user: any; dash: any; cp: any; todos:any[]=[];
  site = environment.webSite+'payment'; img = environment.imageUrl;
  constructor(private ser: SigninService, private api: ManagerService, private apis: ApiService, public toastr: ToastrService, public use: NavComponent) { }

  ngOnInit() {
    this.init();
    this.user = this.ser.userDetails(); // console.log(this.user);
    this.formReset();
    this.Dashboard();
  }

  Dashboard() {
    // this.showMsg('success', 'Welcome', 'You Are Welcome @' + this.user.FullName);
    this.api.getDashboard().subscribe((data: any) => {
      this.dash = data;
    }, (err: HttpErrorResponse) => {
      this.showMsg('error', 'Error', 'Error fetching dashboard data');
    });
  }

  getUserTodo() {
    this.apis.post('todo',this.cp).subscribe((data: any) => {
      this.cp = data;
    }, (err: HttpErrorResponse) => {
      this.showMsg('error', 'Changing Password', 'Current Password is not correct');
    });
  }


  addTodo() {
    this.apis.post('todo',this.cp).subscribe((data: any) => {
      this.cp = data;
    }, (err: HttpErrorResponse) => {
      this.showMsg('error', 'Changing Password', 'Current Password is not correct');
    });
  }

  postPassword() {
    this.ser.changePassword(this.cp).subscribe((data: any) => {
      this.cp = data;
    }, (err: HttpErrorResponse) => {
      this.showMsg('error', 'Changing Password', 'Current Password is not correct');
    });
  }

  showMsg(type, title, message) {
    if (type === 'success') {
      this.toastr.success(message, title);
    } else if (type === 'warning') {
      this.toastr.warning(message, title);
    } else if (type === 'error') {
      this.toastr.error(message, title);
    }
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.dash = { member: 0, male: 0, female: 0, users: 0, trans: 0, sms: 0,
        income: 0, cashbook: 0, assert: 0, teller: 0, expense: 0, liability: 0 };
      this.cp = {
        username: this.user.unique_name, oldpassword: '', newpassword: '', image: '',
        muserid: this.user.Id, mdate: new Date()
      };
    }
  }
  init() {
    this.user = { given_name: '', mobile: '', image: '', typ: '', prn: '', family_name: '', nameid: '', birthdate: '' };
  }

}
